// overall stock report
export const reportHeadingList = '/master-warehouse-report-heading/detail'
export const productWisePriceReport = 'crop-price-info/reports/price-report/product-wise-price-report'
export const dailyPriceListReport = 'crop-price-info/reports/price-report/market-daily-price-report'
export const weeklyPriceListReport = 'crop-price-info/reports/price-report/market-weekly-price-report'
export const yearlyAveragePriceListReport = 'crop-price-info/reports/price-report/yearly-average-price-report'
export const monthlyAveragePriceListReport = 'crop-price-info/reports/price-report/market-monthly-price-report'
export const weeklyMarketWisePriceListReport = 'crop-price-info/reports/price-report/weekly-market-wise-price-report'
export const divisionWiseAvgPriceReport = 'crop-price-info/reports/price-report/division-wise-average-price-report'
export const growerAvgPriceReport = 'crop-price-info/reports/price-report/grower-avg-price-report'
export const marketDirectoryReport = 'crop-price-info/reports/price-report/marketing-directory-report'
export const weeklyAveragePriceListReport = 'crop-price-info/reports/price-report/weekly-average-price-report'
export const comparativeCommodityReport = 'crop-price-info/reports/price-report/comparative-commodity-report'
export const monthlyAverageGraphReport = 'crop-price-info/reports/price-report/market-monthly-price-report'
export const monthlyAverageGrowerGraphReport = 'crop-price-info/reports/price-report/grower-monthly-price-report'
